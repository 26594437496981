import {graphql} from "gatsby";
import {Layout} from "../components/Layout";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import React from "react";
import {Typography} from "../components/Typography";
import * as styles from './404.module.scss';
import {useLocation} from "@reach/router";

export const query = graphql`
    query Page404 {
        services: allContentfulServiceV3(filter: {node_locale: {eq: "en-US"}, isMain: {eq: true}}) {
            nodes {
                name
                slug
                shortDescription {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }

        companyData: contentfulCompanyData(node_locale: {eq: "en-US"}) {
            name
            street
            postalCode
            city
            country
            phones
            emails
        }

        jobs: allContentfulJobOffer(filter: {node_locale: {eq: "en-US"}}) {
            totalCount
        }
    }
`

const PL_LANG = /^\/pl\//;

const TRANSLATIONS = {
    pl: {
        notFound: {
            title: "Nie ma takiej strony",
            description: "Nie ma takiej strony"
        },
    },
    en: {
        notFound: {
            title: "Page not found",
            description: "There is no such page"
        },
    }
}
export default function NotFoundPage({data}) {
    const location = useLocation();
    const lang = PL_LANG.test(location.pathname) ? 'pl' : 'en';

    return <Layout services={data.services.nodes}
                   oldServices={data.oldServices}
                   jobsCount={data.jobs.totalCount}
                   linkableSubServices={data.linkableSubServices}
                   title={TRANSLATIONS[lang].notFound.title}
                   companyData={data.companyData}
                   lang={lang}>
        <PageMainColumnWrapper>
            <Typography className={styles.root}>
                <h1>{TRANSLATIONS[lang].notFound.description}</h1>
            </Typography>
        </PageMainColumnWrapper>
    </Layout>;
}
